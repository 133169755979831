
<template>
  <div>
    <v-card>
      <v-toolbar dense color="color1 color1Text--text">
        <v-toolbar-title class="text-center">Tournaments Results</v-toolbar-title>
        <v-tabs
          v-if="tabs.length > 1"
          slot="extension"
          v-model="tab"
          fixed-tabs
          background-color="color1"
          slider-color="color3"
        >
          <v-tab v-for="(tab, i) in tabs" :key="i" ripple class="color1Text--text">
            {{tab.body}}
            <v-badge inline color="color1Text color1--text">
              <span slot="badge">{{tab.tournaments.length}}</span>
            </v-badge>
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-tabs-items v-model="tab" v-if="tabs.length > 1">
          <v-tab-item v-for="(tab, i) in tabs" :key="i">
            <player-events :tournaments="tab.tournaments" :ps="tab.ps"></player-events>
          </v-tab-item>
        </v-tabs-items>
        <player-events
          v-else
          :tournaments="player.completeTournaments"
          :ps="tab.ps"
          >
        </player-events>
      </v-card-text>
    </v-card>
    <div class="text-right xsmall grey--text">*Bolded points count towards rankings</div>
    <v-expand-transition>
      <div v-if="tab === 'avp'" class="xsmall">
        *AVP finishes only include tournaments run through VolleyballLife.com
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import PlayerEvents from './Events.vue'
import { mapGetters } from 'vuex'

export default {
  props: ['player', 'view'],
  data () {
    return {
      tab: 0,
      debug: false
    }
  },
  computed: {
    ...mapGetters(['subdomain', 'theme', 'user']),
    tabs () {
      if (this.player && this.player.completeTournaments) {
        if (this.debug) {
          return []
        }
        const aau = this.player.completeTournaments.filter(f => f.points.filter(p => p.short === 'AAU').length > 0)
        const aauIds = aau.map(m => m.id)
        const bvne = this.player.completeTournaments.filter(f => f.points.filter(p => p.short === 'BVNE').length > 0)
        const bvneIds = bvne.map(m => m.id)
        const p1440 = this.player.completeTournaments.filter(f => f.points.filter(p => p.short === 'p1440').length > 0)
        const p1440Ids = p1440.map(m => m.id)
        const usavp = this.player.completeTournaments.filter(f => f.points.filter(p => p.short === 'USAVP').length > 0)
        const usavpIds = usavp.map(m => m.id)

        if (this.subdomain && this.subdomain.toLowerCase() === 'aau') {
          return [
            {
              body: 'AAU',
              tournaments: aau,
              ps: 'aau'
            },
            {
              body: 'Other',
              tournaments: this.player.completeTournaments.filter(f => !aauIds.includes(f.id))
            }
          ]
        }
        if (this.subdomain && (this.subdomain.toLowerCase() === 'bvne' || this.subdomain.toLowerCase() === 'gnbv')) {
          return [
            {
              body: 'BVNE',
              tournaments: bvne,
              ps: 'bvne'
            },
            {
              body: 'Other',
              tournaments: this.player.completeTournaments.filter(f => !bvneIds.includes(f.id))
            }
          ]
        }
        if (this.subdomain && this.subdomain.toLowerCase() === 'p1440') {
          return [
            {
              body: 'p1440',
              tournaments: p1440,
              ps: 'p1440'
            },
            {
              body: 'Other',
              tournaments: this.player.completeTournaments.filter(f => !p1440Ids.includes(f.id))
            }
          ]
        }
        if (this.subdomain && (this.subdomain.toLowerCase() === 'avp' || this.subdomain.toLowerCase() === 'usav')) {
          return [
            {
              body: 'USAVP',
              tournaments: usavp,
              ps: 'usavp'
            },
            {
              body: 'Other',
              tournaments: this.player.completeTournaments.filter(f => !usavpIds.includes(f.id))
            }
          ]
        }
        if (this.view.college) {
          return [
            {
              body: 'National Events',
              tournaments: this.player.completeTournaments.filter(f => f.national)
            },
            {
              body: 'Local Events',
              tournaments: this.player.completeTournaments.filter(f => !f.national)
            }
          ]
        }
        var tabs = []
        const ps = this.theme.pointSystems
        if (ps.length) {
          ps.forEach((p) => {
            tabs.push(
              {
                body: p.name,
                tournaments: this.player.completeTournaments.filter(f => f.points.filter(x => x.systemId === p.id).length)
              }
            )
          })
        }

        tabs.push(
          {
            body: 'AAU',
            tournaments: aau
          },
          {
            body: 'BVNE',
            tournaments: bvne
          },
          {
            body: 'p1440',
            tournaments: p1440
          },
          {
            body: 'USAVP',
            tournaments: usavp
          },
          {
            body: 'Other',
            tournaments: this.player.completeTournaments.filter(f => f.points.length === 0 || (!aauIds.includes(f.id) && !bvneIds.includes(f.id) && !p1440Ids.includes(f.id) && !usavpIds.includes(f.id)))
          }
        )

        return tabs.filter(f => f.tournaments.length)
      }
      return []
    }
  },
  mounted () {
    this.tab = this.tabs.findIndex(f => f.tournaments.length > 0)
  },
  components: {
    PlayerEvents
  }
}
</script>

<style scoped>

</style>
